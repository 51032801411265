import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isEmptyInputValue } from './shared';

export function unmaskMin(min: number, inclusive: boolean = false): ValidatorFn {
	return (control: AbstractControl<unknown>) => {
		const { value } = control;
		if (isEmptyInputValue(value) || isEmptyInputValue(min)) {
			return null; // don't validate empty values to allow optional controls
		}
		let stringValue = typeof value === 'string' ? value : `${JSON.stringify(value)}`;
		stringValue = stringValue.replace(/[^0-9.-]/g, '');
		const floatValue = parseFloat(stringValue);
		// Controls with NaN values after parsing should be treated as not having a
		// minimum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-min
		if (!Number.isNaN(floatValue)) {
			if (inclusive) {
				return floatValue >= min ? null : { min: { min: floatValue, actual: value } };
			} else {
				return floatValue > min ? null : { min: { min: floatValue, actual: value } };
			}
		}
		return null;
	};
}
