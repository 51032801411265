import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Quarter } from '../enums';
import { QuarterlyPlan } from '../models';
import { Path } from '../utils/path';

@Injectable()
export class QuarterlyPlanRepository {
	constructor(private afs: AngularFirestore) {}

	public getQuarterlyPlans(tenantId: string, strategyId: string): Observable<QuarterlyPlan[]> {
		const path = Path.quarterlyPlans(tenantId, strategyId);
		const collection = this.afs.collection<QuarterlyPlan>(path).get();
		return collection.pipe(
			map((snapshot) => {
				if (snapshot.empty) return [];
				return snapshot.docs.map((doc) => ({ id: doc.id as Quarter, ...doc.data() }));
			})
		);
	}
}
