import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UpdateData } from '@angular/fire/firestore';

import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { LogService, LogZone } from '@app/shared/core';

import { DEFAULT_PRIORITY, IssueStatus, Priority } from '../enums';
import { Issue, Meeting } from '../models';
import { toDotNotation } from '../utils';
import { Path } from '../utils/path';
import { BaseRepository } from './base-repository.service';
import { UserRepository } from './user.service';

@Injectable()
export class IssueRepository extends BaseRepository<Issue> {
	constructor(
		private afs: AngularFirestore,
		private logService: LogService,
		private userRepository: UserRepository
	) {
		super();
		this.logService = logService.for(LogZone.PERSIST);
	}

	public getIssuesForStatuses(tenantId: string, statuses: IssueStatus[]): Observable<Issue[]> {
		const path = Path.issues(tenantId);
		return this.afs
			.collection<Issue>(path, (ref) => ref.where('resolution', 'in', statuses))
			.valueChanges({ idField: 'id' });
	}

	public getIssuesCreatedByUserBetweenDates(
		tenantId: string,
		userId: string,
		startDate: firebase.firestore.Timestamp,
		endDate: firebase.firestore.Timestamp
	): Observable<Issue[]> {
		const userPath = Path.user(userId);
		const userRef = this.afs.doc(userPath).ref;
		return this.afs
			.collection<Issue>(Path.issues(tenantId), (ref) => {
				let query: firebase.firestore.Query<firebase.firestore.DocumentData> = ref;
				query = query.where('createdBy', '==', userRef);
				query = query.where('createdInMeeting', '==', false);
				if (endDate != null) {
					query = query.where('createdOn', '<', endDate);
				}
				if (startDate != null) {
					query = query.where('createdOn', '>', startDate);
				}
				return query;
			})
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}

	public getIssuesCreatedBefore(tenantId: string, endDate?: firebase.firestore.Timestamp): Observable<Issue[]> {
		const issuesPath = Path.issues(tenantId);
		return this.afs
			.collection<Issue>(issuesPath, (ref) => {
				let query: firebase.firestore.Query<firebase.firestore.DocumentData> = ref;
				if (endDate != null) {
					query = query.where('createdOn', '<', endDate);
				}
				return query;
			})
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}

	public async createIssue(
		tenantId: string,
		name: string,
		description: string,
		createdInMeeting: boolean
	): Promise<void> {
		const currentUser = await firstValueFrom(this.userRepository.currentUser.pipe(first()));
		const resolution = IssueStatus.unreported;
		const priority = DEFAULT_PRIORITY;

		const issue: UpdateData<Issue> = {
			createdBy: currentUser.ref,
			createdOn: firebase.firestore.FieldValue.serverTimestamp(),
			description,
			name,
			resolution,
			priority,
			createdInMeeting,
		};

		const issuesPath = Path.issues(tenantId);
		const issueRef = await this.afs.collection(issuesPath).add(toDotNotation(issue));
		this.logService.info('Create', { path: issueRef.path, item: issue.id }, new Error('For stacktrace'));

		const meetingUpdate = {
			issueUpdates: {
				[issueRef.id]: {
					issue: issueRef,
					resolution,
					priority,
				},
			},
		} as Meeting;

		const meetingsPath = Path.meetings(tenantId);
		const currentMeeting = await firstValueFrom(
			this.afs.collection<Meeting>(meetingsPath, (x) => x.where('isCurrent', '==', true).limit(1)).get()
		);

		if (!currentMeeting.empty) {
			const meetingId = currentMeeting.docs[0].id;
			const meetingPath = Path.meeting(tenantId, meetingId);
			const meetingDoc = this.afs.doc(meetingPath);
			await meetingDoc.ref.update(toDotNotation(meetingUpdate) as Meeting);
			this.logService.debug('Update', { path: meetingDoc.ref.path, item: meetingUpdate });
		}
	}

	public async updateIssue(issuePath: string, resolution: IssueStatus, priority?: Priority): Promise<void> {
		const { tenantId, issueId } = Path.getIds(issuePath);
		const issueDoc = this.afs.doc(issuePath);

		const issueUpdate: UpdateData<Issue> = {
			resolution,
			priority,
			resolutionSetOn: firebase.firestore.FieldValue.serverTimestamp(),
		};

		const meetingUpdate = {
			issueUpdates: {
				[issueId]: {
					issue: issueDoc.ref,
					resolution,
					priority,
				},
			},
		} as Meeting;

		if (!priority) {
			delete issueUpdate.priority;
			delete meetingUpdate.issueUpdates[issueId].priority;
		}

		const meetingsPath = Path.meetings(tenantId);
		const currentMeeting = await firstValueFrom(
			this.afs.collection<Meeting>(meetingsPath, (x) => x.where('isCurrent', '==', true).limit(1)).get()
		);

		const batch = this.afs.firestore.batch();
		batch.update(issueDoc.ref, toDotNotation(issueUpdate) as Issue);
		this.logService.debug('Update', { path: issueDoc.ref.path, item: issueUpdate });

		if (!currentMeeting.empty) {
			const meetingId = currentMeeting.docs[0].id;
			const meetingPath = Path.meeting(tenantId, meetingId);
			const meetingDoc = this.afs.doc(meetingPath);
			batch.update(meetingDoc.ref, toDotNotation(meetingUpdate) as Meeting);
			this.logService.debug('Update', { path: meetingDoc.ref.path, item: meetingUpdate });
		}
		await batch.commit();
	}
}
