/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import firebase from 'firebase/compat/app';

/**
 * Flatten a nested object to only be one level where properties
 * are in dot-notation format. This is needed to make updates in
 * firebase to nested properties without replacing the entire nested object.
 * @param o
 */
export function toDotNotation<T extends object>(o: T): any {
	const map = {} as any;
	for (const prop in o) {
		// eslint-disable-next-line no-prototype-builtins
		if (!o.hasOwnProperty(prop)) continue;
		const value = o[prop];
		if (
			value instanceof firebase.firestore.FieldValue ||
			value instanceof firebase.firestore.Timestamp ||
			value instanceof firebase.firestore.DocumentReference
		) {
			map[prop] = value;
		} else if (value == null) {
			map[prop] = firebase.firestore.FieldValue.delete();
		} else if (Array.isArray(value)) {
			map[prop] = value;
		} else if (typeof value === 'object') {
			const subMap = toDotNotation(value);
			for (const subProp in subMap) {
				if (Object.prototype.hasOwnProperty.call(subMap, subProp)) {
					const subValue = subMap[subProp];
					map[`${prop}.${subProp}`] = subValue;
				}
			}
		} else {
			map[prop] = value;
		}
	}
	return map;
}
