import { StorageType } from '../constants/webstorage.constants';

export class WebStorage {
	public static getItem<T>(key: string, storageType: StorageType = StorageType.localStorage) {
		const value = storageType === StorageType.sessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
		return value ? (JSON.parse(value) as T) : undefined;
	}

	public static setItem<T>(key: string, value: T, storageType: StorageType = StorageType.localStorage): void {
		if (value == null) return;
		if (storageType === StorageType.sessionStorage) {
			sessionStorage.setItem(key, JSON.stringify(value));
		} else {
			localStorage.setItem(key, JSON.stringify(value));
		}
	}

	public static removeItem(key: string, storageType: StorageType = StorageType.localStorage): void {
		if (storageType === StorageType.sessionStorage) {
			localStorage.removeItem(key);
		} else {
			localStorage.removeItem(key);
		}
	}

	public static clear(storageType: StorageType = StorageType.localStorage): void {
		if (storageType === StorageType.sessionStorage) {
			localStorage.clear();
		} else {
			localStorage.clear();
		}
	}
}
