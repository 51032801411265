import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';

import { WebStorage } from '@app/shared/core';
import { Role } from '@app/shared/data';
import { Path } from '@app/shared/data/utils/path';
import { EnvironmentConfig } from '@env/environment-config';

import { LocalStorageKeys } from '../constants';

const INITIAL_PASSWORD_LENGTH = 10 ** 50;

@Injectable({ providedIn: 'root' })
export class AuthService {
	public loggedOut: Observable<boolean>;

	constructor(
		private afAuth: AngularFireAuth,
		private afs: AngularFirestore,
		private environment: EnvironmentConfig
	) {}

	init() {
		this.loggedOut = this.afAuth.authState.pipe(
			filter((x) => !x), // Logged Out
			map((x) => !!x)
		);

		this.loggedOut.subscribe(() => {
			void this.afAuth.signOut();
			for (const key of Object.values(LocalStorageKeys)) {
				localStorage.removeItem(key);
			}
			if (!/^(\/login$)|(\/auth\/)/.test(window.location.pathname)) {
				window.location.href = '/login';
			}
		});
	}

	currentUser() {
		return this.afAuth.user.pipe(
			first(),
			switchMap((user: firebase.User) => this.afs.doc(Path.user(user.uid)).get()),
			shareReplay(1)
		);
	}

	static getRole(): Role {
		const claims: { role: Role } = WebStorage.getItem(LocalStorageKeys.Claims);
		return claims ? claims.role : Role.Viewer;
	}

	async newUserSignup(email: string): Promise<firebase.auth.UserCredential> {
		const sessionName = `Session ${Math.ceil(Math.random() * 1000000)}`;
		const userSignUpSession = firebase.initializeApp(this.environment.firebase, sessionName);
		const credentials = await userSignUpSession
			.auth()
			.createUserWithEmailAndPassword(email, Math.ceil(Math.random() * INITIAL_PASSWORD_LENGTH).toString());
		void userSignUpSession.auth().signOut();
		return credentials;
	}
}
