import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isEmptyInputValue } from './shared';

export function unmaskWholeNumber(): ValidatorFn {
	return (control: AbstractControl<unknown>) => {
		const { value } = control;
		if (isEmptyInputValue(value)) {
			return null; // don't validate empty values to allow optional controls
		}
		let stringValue = typeof value === 'string' ? value : `${JSON.stringify(value)}`;
		stringValue = stringValue.replace(/[^0-9.-]/g, '');
		const floatValue = parseFloat(stringValue);
		const intValue = parseInt(stringValue, 10);
		if (!Number.isNaN(floatValue) || !Number.isNaN(intValue)) {
			return floatValue !== intValue ? { wholeNumber: { wholeNumber: intValue, actual: value } } : null;
		}
		return null;
	};
}
