import { TermKey, TermPipe } from '@app/shared/shared';
import { Permission } from '@app/shared/shared/utils/permission-manager.util';
import { IconName } from '@fortawesome/pro-solid-svg-icons';

export interface AnalyticsSheet {
	order: number;
	icon?: IconName;
	title: string;
	permission?: Permission;
	parent?: string;
	url?: string;
}

export type AnalyticsSheetWithId = AnalyticsSheet & { id: string };

export interface AnalyticsSheets {
	[key: string]: AnalyticsSheet;
}

export const defaultSheets: AnalyticsSheets = {
	'strategy-performance': { order: 10, icon: 'chess', title: 'Strategy Performance' },
	'annual-planning': { order: 20, icon: 'route', title: 'Annual Planning', parent: 'strategy-performance' },
	'board-reports': { order: 30, icon: 'screen-users', title: 'Board Reports', parent: 'strategy-performance' },
	'model-performance': { order: 40, icon: 'magnifying-glass-arrow-right', title: 'Model Performance' },
	'sales-performance': {
		order: 50,
		icon: 'money-check-dollar',
		title: 'Sales Performance',
		permission: Permission.SALES_ANALYTICS,
	},
	'quarterly-forecast': {
		order: 60,
		icon: 'chart-column',
		title: 'Quarterly Forecast',
		parent: 'sales-performance',
		permission: Permission.SALES_ANALYTICS,
	},
	pipeline: {
		order: 70,
		icon: 'filter-circle-dollar',
		title: 'Pipeline',
		parent: 'sales-performance',
		permission: Permission.SALES_ANALYTICS,
	},
	bookings: {
		order: 80,
		icon: 'calendar',
		title: 'Bookings',
		parent: 'sales-performance',
		permission: Permission.SALES_ANALYTICS,
	},
	'pipeline-hygiene': {
		order: 85,
		icon: 'pump-medical',
		title: 'Pipeline Hygiene',
		parent: 'sales-performance',
		permission: Permission.SALES_ANALYTICS,
	},
	'forecast-accuracy': {
		order: 87,
		icon: 'chart-simple',
		title: 'Forecast Accuracy',
		parent: 'sales-performance',
		permission: Permission.SALES_ANALYTICS,
	},
	leaderboard: {
		order: 90,
		icon: 'user-check',
		title: 'Leaderboard',
		parent: 'sales-performance',
		permission: Permission.SALES_ANALYTICS,
	},
	'marketing-performance': {
		order: 100,
		icon: 'messages-dollar',
		title: 'Marketing',
		permission: Permission.MARKETING_ANALYTICS,
	},
	'executive-summary': {
		order: 105,
		icon: 'list',
		title: 'Executive Summary',
		parent: 'marketing-performance',
		permission: Permission.MARKETING_ANALYTICS,
	},
	'activity-performance': { order: 110, icon: 'monitor-waveform', title: 'Activity Performance' },
	'revops-excellence': { order: 120, icon: 'trophy-star', title: 'RevOps Center of Excellence' },
};

export interface TeaserInfo {
	bannerFirstline: string;
	bannerSecondline: string;
	subhead: string;
	cardTexts: string[];
}

export function generateTeaserText(termPipe: TermPipe): Record<string, TeaserInfo> {
	return {
		'strategy-performance': { bannerFirstline: '', bannerSecondline: '', subhead: '', cardTexts: [] },
		'annual-planning': {
			bannerFirstline:
				"The Annual Planning Dashboard presents ayeQ's analysis and recommendations for target setting for the coming year.",
			bannerSecondline: 'Get your ayeQ annual analysis published for your executive team.',
			subhead: 'The dashboard presents:',
			cardTexts: [
				'Analysis of current year performance',
				'Prediction for new year performance',
				'Recommendations on revenue stream segmentation',
				'Risk/focus areas for improvement',
			],
		},
		'board-reports': {
			bannerFirstline:
				'The Board Reports dashboard contains customized analytics visualizations and data for your Board meetings.',
			bannerSecondline:
				'The analytics automatically refresh with current data from any enterprise system, making your Board meeting preparation quick and easy.',
			subhead: 'Make your Board reports available at any time:',
			cardTexts: [
				`Financial ${termPipe.translate(TermKey.kpi, true)}, trends, and actual to goal`,
				'Revenue performance, forecasts, and long-term predictions',
				`Progress on Annual ${termPipe.translate(TermKey.initiative, true)} and strategic ${termPipe.translate(TermKey.kpi, true)}`,
				'Sales and marketing performance',
				'Customer retention and risk scores',
				'And more!',
			],
		},
		'model-performance': { bannerFirstline: '', bannerSecondline: '', subhead: '', cardTexts: [] },
		'sales-performance': { bannerFirstline: '', bannerSecondline: '', subhead: '', cardTexts: [] },
		'quarterly-performance': { bannerFirstline: '', bannerSecondline: '', subhead: '', cardTexts: [] },
		'quarterly-forecast': {
			bannerFirstline: 'Add this dashboard to predict and manage sales attainment in the current quarter.',
			bannerSecondline: 'Manage quarterly sales performance and optimize sales execution with RevOps discipline.',
			subhead:
				'The Quarterly Forecast Dashboard shows you current quarter performance in total, by person and by team:',
			cardTexts: [
				'QTD bookings, amount to go',
				'Forecast bookends - worst and best case scenarios',
				'Current quarter deals by commit/best case',
				'Deals that need attention (at risk, stuck, past due)',
				'Pipeline change analysis - what changed between 2 dates and why',
				'And more!',
			],
		},
		pipeline: {
			bannerFirstline: 'Add this dashboard to understand the health of your pipeline.',
			bannerSecondline: 'Optimize sales execution and implement tactics to generate pipeline that can cover gaps.',
			subhead: 'The Pipeline Dashboard shows where you have sufficient coverage, and where you are gapped:',
			cardTexts: [
				'Total pipeline by opportunity type',
				'Pipeline by quarter and rep, revenue stream, stage, forecast category',
				'Pipeline change analysis (what changed between 2 dates: added, revised, retired',
				'And more!',
			],
		},
		bookings: {
			bannerFirstline: 'Add this dashboard to see your historic bookings performance and trend.',
			bannerSecondline: 'Understand where to invest/redirect resources to optimize growth.',
			subhead: 'The Bookings Performance Dashboard shows you:',
			cardTexts: [
				'Current performance compared to prior year',
				'Comparison of performance by attributes, such as revenue stream, ARR/services/TCV, rep, product, new logo/expansion',
				'Bookings trends by periods (YoY, QoQ, additional time segments)',
				'And more!',
			],
		},
		'pipeline-hygiene': {
			bannerFirstline: 'Add this dashboard to see how your sales pipeline is adhering to your RevOps process.',
			bannerSecondline: 'Drive RevOps adoption and discipline by showing your sales reps areas of risk.',
			subhead: 'The Pipeline Hygiene shows you:',
			cardTexts: [
				'Stuck Opportunities by Stage Age and Total Age',
				'Pipeline Aging by various multiples of aging',
				'Red Flag Opportunities by various risk indicators',
				'Forecast Accuracy by Team/Rep',
			],
		},
		leaderboard: {
			bannerFirstline: 'Add this dashboard to see how reps are performing against their plan.',
			bannerSecondline: 'Optimize your team structure and focus your training/coaching investment.',
			subhead: 'The Sales Rep Performance Dashboard shows you metrics by rep and sales team:',
			cardTexts: [
				'Stack ranks by rep: QTD/YTD Attainment',
				'Stage conversion rate/aging against model target and against other rep performance',
				'Red flag performance',
				'And more!',
			],
		},
		'marketing-performance': { bannerFirstline: '', bannerSecondline: '', subhead: '', cardTexts: [] },
		'executive-summary': {
			bannerFirstline: 'Add this dashboard to see a strategic summary of marketing metrics.',
			bannerSecondline:
				'Get the key takeaways that allow you to understand marketing performance from a growth perspective.',
			subhead: 'The Executive Summary for Marketing includes the following:',
			cardTexts: [
				'Marketing contribution to Pipeline',
				'Marketing contribution to Bookings',
				'Overall conversion rates from lead to qualification to deal',
				'Marketing ROI by campaign',
				'Marketing influence on pipeline',
				'And more!',
			],
		},
		'activity-performance': {
			bannerFirstline:
				'Add this dashboard to optimize the performance of your qualification team (business development/inside sales).',
			bannerSecondline: '',
			subhead: 'The Activity Dashboard shows you performance metrics by person and team:',
			cardTexts: [
				'Activity volume (emails, calls, meetings, etc.)',
				'Lead conversion rates',
				'Marketing program/campaign follow up and conversions',
				'Lead aging and SLA performance',
				'New opportunity creation versus target',
				'And more!',
			],
		},
		'revops-excellence': {
			bannerFirstline: 'The RevOps Center of Excellence dashboard shows you the key metrics for RevOps success.',
			bannerSecondline: 'Measure your progress on the RevOps journey.',
			subhead: 'The dashboard presents your RevOps scorecard:',
			cardTexts: [
				'RevOps maturity score',
				'RevOps adoption score - overall and by team/rep',
				'RevOps process adherence score - overall and by team/rep',
				'Pipeline hygiene scores (stuck, red flag activity, data quality)',
				'Rep/Team leaderboards',
				'And more!',
			],
		},
		'forecast-accuracy': {
			bannerFirstline: 'Add this dashboard to track forecast accuracy - by sales rep, team, or company.',
			bannerSecondline: 'Get control over your numbers and precision in your forecasting.',
			subhead:
				'The Forecast Accuracy Dashboard tracks forecasts at various points in time, and compare the forecast to actual performance:',
			cardTexts: [
				'Forecast snapshots by month',
				'Comparison of forecast to actual bookings',
				'Forecast accuracy rankings by rep, team, or company',
				'Trending in forecast over the quarter',
				'Ranking by forecast accuracy score',
				'And more!',
			],
		},
	};
}
