export function unmaskNumber(value: unknown): number | null {
	if (typeof value === 'number') return value;
	if (value == null || typeof value !== 'string') {
		return null;
	}
	// remove all non-numeric characters
	const val = value.replace(/[^0-9.-]/g, '');
	const intValue = parseInt(val, 10);
	return Number.isNaN(intValue) ? null : intValue;
}

export function unmaskFloat(value: unknown): number | null {
	if (typeof value === 'number') return value;
	if (value == null || typeof value !== 'string') {
		return null;
	}
	// remove all non-numeric characters
	const val = value.replace(/[^0-9.-]/g, '');
	const floatValue = parseFloat(val);
	return Number.isNaN(floatValue) ? null : floatValue;
}
