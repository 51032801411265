import { firstValueFrom, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { QuarterlyPlan } from '../models';
import { Path } from '../utils';
import { BaseDAO } from './base.dao';
import { ImperativeDAO } from './imperative.dao';
import { WeeklyUpdateDAO } from './weekly-update.dao';

export class QuarterlyPlanDAO extends BaseDAO<QuarterlyPlan> {
	private _imperatives: Observable<ImperativeDAO[]>;
	private _weeklyUpdates: Observable<WeeklyUpdateDAO[]>;

	public get imperatives(): Observable<ImperativeDAO[]> {
		if (this._imperatives == null) {
			const path = `${this.path}/imperatives`;
			this._imperatives = this.initCollection(ImperativeDAO, path);
		}
		return this._imperatives;
	}

	public imperativesForUser(userId: string) {
		return this.imperatives.pipe(
			map((imperatives) => imperatives.filter((imperative) => imperative.snapshot.owner.id === userId))
		);
	}

	public get weeklyUpdates(): Observable<WeeklyUpdateDAO[]> {
		if (this._weeklyUpdates == null) {
			const { tenantId, strategyId, quarterlyPlanId } = Path.getIds(this.path);
			const path = Path.weeklyUpdates(tenantId, strategyId, quarterlyPlanId);
			this._weeklyUpdates = this.initCollection(WeeklyUpdateDAO, path);
		}
		return this._weeklyUpdates;
	}

	public weeklyUpdateForUserAndDate(
		userId: string,
		startDate: { dayOfMonth: number; month: number }
	): Observable<WeeklyUpdateDAO> {
		return this.weeklyUpdates.pipe(
			map((weeklyUpdates = []) =>
				weeklyUpdates.find(
					(weeklyUpdate) =>
						weeklyUpdate.snapshot.startDate &&
						weeklyUpdate.snapshot.startDate.dayOfMonth === startDate.dayOfMonth &&
						weeklyUpdate.snapshot.startDate.month === startDate.month &&
						weeklyUpdate.snapshot.user.id === userId
				)
			)
		);
	}

	public weeklyUpdatesForDate(startDate: { dayOfMonth: number; month: number }): Observable<WeeklyUpdateDAO[]> {
		if (!startDate) return of(<WeeklyUpdateDAO[]>[]);
		return this.weeklyUpdates.pipe(
			map((weeklyUpdates = []) =>
				weeklyUpdates.filter(
					(weeklyUpdate) =>
						weeklyUpdate.snapshot.startDate &&
						weeklyUpdate.snapshot.startDate.dayOfMonth === startDate.dayOfMonth &&
						weeklyUpdate.snapshot.startDate.month === startDate.month
				)
			)
		);
	}

	public async isValid(): Promise<boolean> {
		if (!this.snapshot) {
			return false;
		}
		return firstValueFrom(
			this.imperatives.pipe(
				map((imperatives) =>
					imperatives.every(
						(imperative) =>
							!!imperative.snapshot &&
							!!imperative.snapshot.name &&
							!!imperative.snapshot.owner &&
							!!imperative.snapshot.successCriteria
					)
				)
			)
		);
	}
}
