import { Component, inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { firstValueFrom } from 'rxjs';

import { faTriangleExclamation } from '@fortawesome/pro-thin-svg-icons';

export interface DeleteDialogData {
	type?: string;
	name: string;
	additionalText?: string;
	content?: TemplateRef<unknown>;
}

@Component({
	selector: 'ayeq-delete-dialog',
	template: `
		<div class="title-box">
			<fa-icon [icon]="icon" size="3x"></fa-icon>
			<div>You are about to delete {{ data.type ? 'the ' + data.type + ':' : '' }} "{{ data.name }}"</div>
		</div>
		<div mat-dialog-content>
			<div #contentRef>
				<ng-content></ng-content>
			</div>
			<ng-container *ngIf="!contentRef.childElementCount">
				Are you sure you want to permanantly delete it?
				<div *ngIf="data.additionalText">{{ data.additionalText }}</div>
			</ng-container>
		</div>
		<div mat-dialog-actions align="end">
			<button mat-raised-button color="secondary" (click)="dialogRef.close(false)">Cancel</button>
			<ng-container *ngIf="!btnRef.childElementCount">
				<button mat-raised-button color="warn" (click)="dialogRef.close(true)">Yes, Delete</button>
			</ng-container>
			<div #btnRef>
				<ng-content select="button"></ng-content>
			</div>
		</div>
	`,
	styleUrl: './delete-dialog.component.scss',
})
export class DeleteDialogComponent<T extends DeleteDialogData> {
	readonly icon = faTriangleExclamation;

	readonly dialogRef = inject<MatDialogRef<DeleteDialogComponent<T>, boolean>>(MatDialogRef);
	readonly data: T = inject(MAT_DIALOG_DATA) as T;
}

export function openDeleteDialog(
	dialog: MatDialog,
	data: DeleteDialogData,
	config?: MatDialogConfig
): Promise<boolean> {
	return firstValueFrom(
		dialog
			.open<DeleteDialogComponent<DeleteDialogData>, DeleteDialogData, boolean>(DeleteDialogComponent, {
				width: '500px',
				autoFocus: false,
				data,
				...(config ?? {}),
			})
			.beforeClosed()
	);
}
