import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import firebase from 'firebase/compat/app';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { MeetingTimePeriod } from '@app/features/execution/services/meeting.service';

import { ActionItemStatus } from '../enums';
import { ActionItem } from '../models';
import { Path } from '../utils/path';
import { BaseRepository, COMBINED_QUERY_DEBOUNCE } from './base-repository.service';

@Injectable()
export class StrategyTrackingActionItemRepository extends BaseRepository<ActionItem> {
	constructor(private afs: AngularFirestore) {
		super();
	}

	public getActionItemsForStrategyTracking(
		tenantId: string,
		userId: string,
		meetingPeriod: MeetingTimePeriod
	): Observable<ActionItem[]> {
		const actionItemsPath = Path.actionItems(tenantId);
		const userPath = Path.user(userId);
		const userRef = this.afs.doc(userPath).ref;
		return combineLatest([
			this.getActionItemsUnresolvedBeforeMeetingEnd(actionItemsPath, userRef, meetingPeriod.end),
			this.getActionItemsUpdatedInMeeting(actionItemsPath, userRef, meetingPeriod),
		]).pipe(
			debounceTime(COMBINED_QUERY_DEBOUNCE),
			map((x) => this.mapUnique(x))
		);
	}

	private getActionItemsUnresolvedBeforeMeetingEnd(
		actionItemsPath: string,
		userRef: DocumentReference,
		endDate: firebase.firestore.Timestamp
	): Observable<ActionItem[]> {
		return this.afs
			.collection<ActionItem>(actionItemsPath, (ref) => {
				let query = ref
					.where('owner', '==', userRef)
					.where('status', 'in', [
						ActionItemStatus.inProgress,
						ActionItemStatus.notStarted,
						ActionItemStatus.unreported,
					]);
				if (endDate) {
					query = query.where('createdOn', '<', endDate);
				}
				return query;
			})
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}

	private getActionItemsUpdatedInMeeting(
		actionItemsPath: string,
		userRef: DocumentReference,
		meetingPeriod: MeetingTimePeriod
	): Observable<ActionItem[]> {
		return this.afs
			.collection<ActionItem>(actionItemsPath, (ref) => {
				let query = ref.where('owner', '==', userRef);
				if (meetingPeriod.end) {
					query = query.where('createdOn', '<', meetingPeriod.end);
				}
				return query;
			})
			.snapshotChanges()
			.pipe(
				map((x) =>
					// we can't query on two separate dates so filter on the other here
					this.mapListSnapshot(x).filter(
						(y) => meetingPeriod.start == null || (y.statusUpdatedOn && y.statusUpdatedOn > meetingPeriod.start)
					)
				)
			);
	}

	public doesIssueHaveActionItems(tenantId: string, issueId: string): Observable<boolean> {
		const actionItemsPath = Path.actionItems(tenantId);
		const issuePath = Path.issue(tenantId, issueId);
		const issueDoc = this.afs.doc(issuePath);
		return this.afs
			.collection(actionItemsPath, (ai) => ai.where('issue', '==', issueDoc.ref))
			.get()
			.pipe(map((actionItems) => !actionItems.empty));
	}
}
