import { DecimalPipe } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';

import { merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { unmaskNumber } from '../../utils';
import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-number-input',
	template: `
		<mat-form-field appearance="outline" [ngClass]="fieldClass">
			<div class="position-relative">
				<input
					name="{{ name }}"
					type="number"
					[formControl]="control"
					matInput
					(input)="handleInput($event)"
					(keydown)="handleKeyDown($event)"
					[attr.maxlength]="maxlength ? maxlength : null"
				/>
				<div class="actual"><ng-content select="[actual]"></ng-content></div>
			</div>
		</mat-form-field>
	`,
	styleUrls: ['./input-hint.component.scss', './actual-value.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NumberInputComponent extends InputComponent<object, number, string> {
	@Input() maxlength: number = null;
	@Input() fieldClass: string | string[] | Set<string> | { [klass: string]: unknown } | null | undefined;

	inputSubject = new Subject<number>();
	public viewModel: Observable<string>;

	constructor(
		log: LogService,
		persistenceService: PersistenceService,
		private decimalPipe: DecimalPipe
	) {
		super(log, persistenceService, 'blur');
		this.viewModel = merge(this.inputSubject, this._model.pipe(map((x) => this.mapValueFromModel(x)))).pipe(
			map((value) => this.decimalPipe.transform(value))
		);
	}

	handleInput(event: Event) {
		const { value } = event.target as HTMLInputElement;
		const sanitizedValue = parseFloat(value.replace(/[^0-9.]/g, ''));
		this.inputSubject.next(sanitizedValue);
	}

	handleKeyDown(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			(event.target as HTMLInputElement).blur();
		} else if (event.key === 'e' || event.key === '-') {
			event.preventDefault();
		}
	}

	override unmask(value: unknown): number {
		return unmaskNumber(value);
	}
}
