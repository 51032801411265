import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class DownloadService {
	private renderer: Renderer2;

	constructor(rendererFactory: RendererFactory2) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	downloadFile(content: string, filename: string, mediaType: string): void {
		const contentType = `data:${mediaType};charset=utf-8,`;
		const uri = encodeURI(`${contentType}${content}`);
		const link = this.renderer.createElement('a') as HTMLAnchorElement;
		this.renderer.setAttribute(link, 'download', filename);
		this.renderer.setAttribute(link, 'href', uri);
		this.renderer.appendChild(document.body, link);
		link.click();
		this.renderer.removeChild(document.body, link);
	}
}
