import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import {
	ConsoleAppender, LOG_ZONE, LogCoreService, LogService, LogSettingsService, LogZone, QlikService, SnackBarService,
	StackdriverAppender,
} from './services';
import { MetricsService } from './services/metrics.service';
import { ScreenSizeService } from './services/screen-size.service';

@NgModule({
	imports: [MatSnackBarModule, CommonModule, AngularFireFunctionsModule],
	providers: [
		LogCoreService,
		LogSettingsService,
		LogService,
		ConsoleAppender,
		{ provide: LOG_ZONE, useValue: LogZone.GLOBAL },
		QlikService,
		ScreenSizeService,
		StackdriverAppender,
		SnackBarService,
		MetricsService,
		{ provide: REGION, useValue: 'us-central1' },
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error(`CoreModule has already been loaded. import Core modules in the AppModule only.`);
		}
	}
}
