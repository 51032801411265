import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'error-dialog',
	templateUrl: 'error-dialog.component.html',
	styles: [
		`
			a {
				color: #00a1e0;
				text-decoration: none;
			}
		`,
	],
})
export class ErrorDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<ErrorDialogComponent>,
		private afAuth: AngularFireAuth
	) {}

	async logout() {
		await this.afAuth.signOut();
	}

	refresh() {
		window.location.reload();
	}
}
