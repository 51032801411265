import { AfterContentInit, Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UpdateData } from '@angular/fire/firestore';
import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import firebase from 'firebase/compat/app';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { LogService } from '@app/shared/core';
import { PersistenceService, toDotNotation } from '@app/shared/data';

export class InputConfig<TModel, TValue, TDoc = TModel> {
	constructor(
		public name: string,
		public valueFromModel: (model: TModel) => TValue,
		public valueToModel: (value: TValue | firebase.firestore.FieldValue) => UpdateData<TModel>,
		public validators: ValidatorFn[],
		public modelToDocument: (model: UpdateData<TModel>) => UpdateData<TDoc> = (model) => model as UpdateData<TDoc>
	) {}
}

export class ListInputConfig<TModel, TValue, TKey, TDoc = TModel> {
	constructor(
		public name: string,
		public valueFromModel: (model: TModel, key: TKey) => TValue,
		public valueToModel: (value: TValue | firebase.firestore.FieldValue, key: TKey) => UpdateData<TModel>,
		public validators: ValidatorFn[],
		public modelToDocument: (model: UpdateData<TModel>) => UpdateData<TDoc> = (model) => model as UpdateData<TDoc>
	) {}
}

/**
 * Abstract component that provides reusable logic for single field controls.
 */
@Directive()
export abstract class InputComponent<TModel extends object, TValue, TKey extends string>
	implements AfterContentInit, OnDestroy
{
	protected readonly _sub = new Subscription();
	protected _modelSub: Subscription;
	protected readonly _onFormUpdateFromModel = new Subject<TValue>();
	protected readonly _reset = new BehaviorSubject<void>(null);
	private readonly _valueChanges: Observable<TValue>;
	protected readonly _path = new ReplaySubject<string>(1);
	protected readonly _model = new ReplaySubject<TModel>(1);
	private _modelValue: Observable<TValue>;

	public readonly control: FormControl<TValue>;
	public readonly errors: Observable<ValidationErrors>;
	public readonly isValid: Observable<boolean>;
	private readonly validatorsSet = new ReplaySubject<void>();

	@Input()
	public set dao(value: { path: string; stream: Observable<TModel> }) {
		if (value == null) return;
		this._path.next(value.path);
		const hasSub = this._modelSub != null;
		if (hasSub) {
			this._modelSub.unsubscribe();
		}
		this._modelSub = value.stream.subscribe((x) => {
			this._model.next(x);
		});
		// don't need to reset if this is the first time it is set
		if (hasSub) {
			this.reset();
		}
	}

	@Input()
	public config: InputConfig<TModel, TValue> | ListInputConfig<TModel, TValue, TKey>;

	@Input()
	public key: TKey;

	@Input()
	public set disabled(value: boolean) {
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions
		value ? this.control.disable() : this.control.enable();
	}

	@Output() updated = new EventEmitter<TValue | firebase.firestore.FieldValue>();

	public get name(): string {
		return this.key && this.config ? `${this.config.name}(${this.key})` : this.config?.name;
	}

	/**
	 * All value changes on the form.
	 * Including those that don't persist to the model.
	 */
	public get valueChanges(): Observable<TValue> {
		return this._valueChanges;
	}

	public get value(): TValue {
		return this.control.valid ? this.unmask(this.control.value) : null;
	}

	constructor(
		protected log: LogService,
		protected persistenceService: PersistenceService,
		updateOn: 'blur' | 'change' | 'submit'
	) {
		this.control = new FormControl<TValue>(null, { updateOn });
		this._valueChanges = this.initValueChanges();
		this.errors = this.initErrors();
		this.isValid = this.initIsValid();
	}

	protected mapValueFromModel(model: TModel): TValue {
		switch (this.config.constructor) {
			case InputConfig: {
				const config = this.config as InputConfig<TModel, TValue>;
				return config.valueFromModel(model);
			}
			case ListInputConfig: {
				const config = this.config as ListInputConfig<TModel, TValue, TKey>;
				return config.valueFromModel(model, this.key);
			}
		}
	}

	protected mapValueToModel(value: TValue | firebase.firestore.FieldValue): Partial<TModel> {
		switch (this.config.constructor) {
			case InputConfig: {
				const config = this.config as InputConfig<TModel, TValue>;
				return config.modelToDocument(config.valueToModel(value));
			}
			case ListInputConfig: {
				const config = this.config as ListInputConfig<TModel, TValue, TKey>;
				return config.modelToDocument(config.valueToModel(value, this.key));
			}
		}
	}

	private initErrors(): Observable<ValidationErrors> {
		return this.valueChanges.pipe(map(() => (this.control.invalid && this.control.dirty ? this.control.errors : null)));
	}

	private initIsValid(): Observable<boolean> {
		return combineLatest([this.validatorsSet, this.valueChanges]).pipe(
			map(() => this.control.validator?.(new FormControl(this.control.value)) == null)
		);
	}

	/**
	 * Unmask the field control vaue.
	 * @param value
	 */
	protected abstract unmask(value: unknown): TValue;

	private initValueChanges(): Observable<TValue> {
		return merge(this.control.valueChanges, this._onFormUpdateFromModel).pipe(startWith(this.value), shareReplay());
	}

	/**
	 * Initialize the bindings.
	 * Have it as a separate function rather than calling it in the constructor
	 * bc the implementing classes may make use of services injected into
	 * the constructor in their abstract implementations which wont be available
	 * when they are called by the base class.
	 * @returns Binding subscription.
	 */
	public ngAfterContentInit(): void {
		this.control.setValidators(this.config.validators);
		this.validatorsSet.next();
		this.control.updateValueAndValidity();
		const model = this._model.pipe(map((x) => this.mapValueFromModel(x)));
		// reset distinctUntilChanged when the form is reset
		this._modelValue = this._reset.pipe(switchMap(() => model.pipe(distinctUntilChanged())));
		this._sub.add(this.bindModelToForm());
		this._sub.add(this.bindFormToModel());
		this.log.debug(`Init [${this.name}]`);
	}

	/**
	 * Bind the model to the form so that changes from the DB
	 * are reflected in the form.
	 */
	private bindModelToForm(): Subscription {
		return this._modelValue
			.pipe(
				// Filter out null values if the control isn't valid
				// as we want the user to still see their invalid value till they fix it.
				// Slight chance that one user deletes it and another has it as invalid
				// and it isn't removed but we have to pick something.
				filter((x) => x != null || (this.control.valid && this.control.dirty))
			)
			.subscribe((value) => {
				this.control.setValue(value, { emitEvent: false });
				this.control.markAsDirty();
				this.log.debug(`Form Update [${this.name}]`, value);
				this._onFormUpdateFromModel.next(value);
			});
	}

	/**
	 * Bind the form to the model so that form changes
	 * are presisted.
	 */
	private bindFormToModel(): Subscription {
		return this.control.valueChanges
			.pipe(
				map((x) => this.unmask(x)),
				// don't persist if it is the same as the model
				withLatestFrom(this._modelValue),
				filter(
					([value, model]) =>
						// needs to be deleted
						(this.control.invalid && model != null) ||
						// valid but different
						(this.control.valid && value !== model)
				),
				// setup to delete if invalid or empty
				map(([value]) => (this.control.invalid || value == null ? firebase.firestore.FieldValue.delete() : value)),
				withLatestFrom(this._path)
			)
			.subscribe(([value, path]) => {
				if (this.updated.observed) {
					this.updated.emit(value);
				} else {
					// map to the model for type safety and then flatten to dot notation
					// so the patch only affects what it should
					void this.persistenceService.patch(path, toDotNotation(this.mapValueToModel(value)) as TModel);
				}
				this.log.debug(`Model Update [${this.name}]`, value);
			});
	}

	/**
	 * Reset the form control.
	 */
	protected reset(): void {
		this.control.reset(null, { emitEvent: false });
		// reset the distinctUntilChanged opperator
		this._reset.next();
		this.log.debug(`Reset [${this.name}]`);
		// might fire again when the model updates the form but
		// that wont happen if the value is null
		this._onFormUpdateFromModel.next(null);
	}

	public ngOnDestroy(): void {
		if (this._modelSub) {
			this._modelSub.unsubscribe();
		}
		this._sub.unsubscribe();
	}
}
