import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { LogService } from '@app/shared/core';
import { PersistenceService } from '@app/shared/data';

import { InputComponent } from '../input.component';

@Component({
	selector: 'ayeq-text-input',
	template: `
		<mat-form-field appearance="outline" [ngClass]="fieldClass">
			<input
				name="{{ name }}"
				[formControl]="control"
				matInput
				[placeholder]="placeholder"
				(keydown.enter)="$any($event).target.blur()"
				[attr.maxlength]="maxlength ? maxlength : null"
			/>
		</mat-form-field>
	`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputComponent extends InputComponent<object, string, string> {
	@Input() placeholder = '';
	@Input() maxlength: number = null;
	@Input() fieldClass: string | string[] | Set<string> | { [klass: string]: unknown } | null | undefined;

	constructor(log: LogService, persistenceService: PersistenceService) {
		super(log, persistenceService, 'blur');
	}

	override unmask(x: unknown): string {
		return x as string;
	}
}
