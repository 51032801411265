import { Pipe, PipeTransform } from '@angular/core';

import { BaseDAO } from '@app/shared/data';

@Pipe({ name: 'snapshotSortBy' })
export class SnapshotSortByPipe<T extends object> implements PipeTransform {
	transform(items: BaseDAO<T>[], key: keyof T, reverse: boolean = false) {
		return items.sort((a, b) => {
			const aKey = a.snapshot[key] as number;
			const bKey = b.snapshot[key] as number;
			return reverse ? bKey - aKey : aKey - bKey;
		});
	}
}
