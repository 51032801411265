import { Component, Input } from '@angular/core';
import { UpdateData } from '@angular/fire/firestore';
import { Router } from '@angular/router';

import firebase from 'firebase/compat/app';
import { firstValueFrom, map, Observable } from 'rxjs';

import { GtmProgressType, isGtmFinished, isGtmStepComplete, PersistenceService, Strategy } from '@app/shared/data';

import { StrategyService } from '../../services';

@Component({
	selector: 'ayeq-gtm-buttons',
	templateUrl: './gtm-buttons.component.html',
})
export class GtmButtonsComponent {
	@Input({ required: true }) for: GtmProgressType;

	get isModel() {
		return ['PROCESS', 'MODEL'].includes(this.for);
	}

	readonly vm$: Observable<{ gtmComplete: boolean; stepComplete: boolean }>;

	constructor(
		private router: Router,
		private strategyService: StrategyService,
		private persistenceService: PersistenceService
	) {
		this.vm$ = strategyService.selectedStrategy.pipe(
			map((s) => ({
				gtmComplete: isGtmFinished(s.snapshot),
				stepComplete: isGtmStepComplete(s.snapshot, this.for),
			}))
		);
	}

	async mark() {
		const dao = await firstValueFrom(this.strategyService.selectedStrategy);
		const isComplete = isGtmStepComplete(dao.snapshot, this.for);
		if (!isComplete) {
			await this.persistenceService.patch(dao.path, {
				gtmProgress: firebase.firestore.FieldValue.arrayUnion(this.for),
			} as UpdateData<Strategy>);
			await this.router.navigateByUrl('/');
		} else {
			await this.persistenceService.patch(dao.path, {
				gtmProgress: firebase.firestore.FieldValue.arrayRemove(this.for),
			} as UpdateData<Strategy>);
		}
	}
}
