import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const powers = [
	{ key: 'T', value: 10 ** 12 },
	{ key: 'B', value: 10 ** 9 },
	{ key: 'M', value: 10 ** 6 },
	{ key: 'K', value: 1000 },
];

@Pipe({
	name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
	constructor(private numberPipe: DecimalPipe) {}

	transform(number: number, maxDecimals = 1) {
		if (number == null || Number.isNaN(number)) {
			return null;
		} // will only work value is a number
		let abs = Math.abs(number);
		const rounder = 10 ** maxDecimals;
		const isNegative = number < 0; // will also work for Negetive numbers
		let key = '';

		for (let i = 0; i < powers.length; i++) {
			let reduced = abs / powers[i].value;
			reduced = Math.round(reduced * rounder) / rounder;
			if (reduced >= 1) {
				abs = reduced;
				key = powers[i].key;
				break;
			}
		}
		const value = (isNegative ? -1 : 1) * abs;
		const result = this.numberPipe.transform(value, `1.0-${maxDecimals}`);
		return result + key;
	}
}
