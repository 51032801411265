import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentReference, QueryFn } from '@angular/fire/compat/firestore';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { AuthService } from '@app/shared/shared/services/auth.service';

/**
 * Relates a DAO to its snapshot and stream source.
 * Used for entities that are streamed from a collection.
 */
interface CollectionDocDao<Tm extends object, Td extends BaseDAO<Tm>> {
	data: BehaviorSubject<Tm>;
	dao: Td;
}

/**
 * Strategy for how to get the snapshot and stream for a DAO.
 */
interface DaoValue<Tm extends object> {
	snapshot: Tm;
	stream: Observable<Tm>;
}

/**
 * Strategy for how to get the snapshot and stream for
 * a DAO that is streamed as part of a collection.
 */
class CollectionDaoValue<Tm extends object> implements DaoValue<Tm> {
	public get snapshot(): Tm {
		return this._data.value;
	}

	public get stream(): Observable<Tm> {
		return this._data;
	}

	constructor(private _data: BehaviorSubject<Tm>) {}
}

/**
 * Factory for creating a DAO tree starting with a root doc.
 */
@Injectable()
export class DaoFactory {
	public constructor(
		private afs: AngularFirestore,
		private auth: AuthService
	) {}

	public static build<Tm extends object, Td extends BaseDAO<Tm>>(
		afs: AngularFirestore,
		auth: AuthService,
		DAO: new (...args: unknown[]) => Td,
		path: string
	): Observable<Td> {
		const doc = afs.doc<Tm>(path);
		let dao: Td;
		let stream: BehaviorSubject<Tm>;
		return doc.snapshotChanges().pipe(
			takeUntil(auth.loggedOut),
			map((action) => {
				const { id } = action.payload.ref;
				const snapshot = action.payload.data();
				if (!dao) {
					stream = new BehaviorSubject<Tm>(snapshot);
					const value = new CollectionDaoValue(stream);
					dao = new DAO(afs, auth, id, path, value, doc.ref);
					return dao;
				}
				stream.next(snapshot);
				return dao;
			}),
			shareReplay(1)
		);
	}

	/**
	 * Create the root DAO for the given path.
	 * @param DAO DAO constructor
	 * @param path Path to the root doc.
	 */
	public build<Tm extends object, Td extends BaseDAO<Tm>>(
		DAO: new (...args: unknown[]) => Td,
		path: string
	): Observable<Td> {
		return DaoFactory.build(this.afs, this.auth, DAO, path);
	}
}

/**
 * Wrapper for a firestore entity that allows
 * lazy access to its sub-collections.
 */
export class BaseDAO<Tm extends object> {
	private _cacheMap = new Map<string, Observable<BaseDAO<object>>>();
	/**
	 * Last known value.
	 */
	public get snapshot(): Tm {
		return this._data.snapshot;
	}

	/**
	 * Stream of values.
	 */
	public get stream(): Observable<Tm> {
		return this._data.stream;
	}

	public constructor(
		protected _afs: AngularFirestore,
		protected _auth: AuthService,
		public id: string,
		public path: string,
		protected _data: DaoValue<Tm>,
		public ref: DocumentReference<Tm>
	) {}

	/**
	 * Create an observable of DAOs for the sub-collection.
	 * @param DAO DAO constructor.
	 * @param path Path to the collection.
	 */
	protected initCollection<Tmi extends object, Td extends BaseDAO<Tmi>>(
		DAO: new (...args: unknown[]) => Td,
		path: string,
		queryFn?: QueryFn
	): Observable<Td[]> {
		return DAOCollectionFactory.initCollection(this._afs, this._auth, DAO, path, queryFn);
	}

	protected getSubDocumentDao<Td extends BaseDAO<Ts>, Ts extends object>(
		selector: (snap: Tm) => DocumentReference<Ts>,
		DAO: new (...args: unknown[]) => Td
	): Observable<Td> {
		const subDoc = selector(this.snapshot);
		if (!subDoc) return of(null);
		let cached = this._cacheMap.get(subDoc.id);
		if (!cached) {
			cached = DaoFactory.build(this._afs, this._auth, DAO, subDoc.path);
			this._cacheMap.set(subDoc.id, cached);
		}
		return cached as Observable<Td>;
	}

	protected getParentDao<Td extends BaseDAO<Ts>, Ts extends object>(
		DAO: new (...args: unknown[]) => Td
	): Observable<Td> {
		const parentDoc = this._afs.doc(this.path.replace(/\/\w+\/\w+$/, '')).ref;
		if (!parentDoc) return of(null);
		let cached = this._cacheMap.get(parentDoc.id);
		if (!cached) {
			cached = DaoFactory.build(this._afs, this._auth, DAO, parentDoc.path);
			this._cacheMap.set(parentDoc.id, cached);
		}
		return cached as Observable<Td>;
	}
}

/**
 * Static class used to generate DAO collections.
 */
@Injectable()
export class DAOCollectionFactory {
	public constructor(
		private afs: AngularFirestore,
		private auth: AuthService
	) {}

	/**
	 * Create an observable of DAOs for the sub-collection.
	 * @param DAO DAO constructor.
	 * @param path Path to the collection.
	 */
	public static initCollection<Tm extends object, Td extends BaseDAO<Tm>>(
		afs: AngularFirestore,
		auth: AuthService,
		DAO: new (...args: unknown[]) => Td,
		path: string,
		queryFn?: QueryFn
	): Observable<Td[]> {
		const collection = afs.collection<Tm>(path, queryFn);
		let daoMap = new Map<string, CollectionDocDao<Tm, Td>>();
		/**
		 * Provides a list of the last actions performed for each entity.
		 * Upon initial subscription all will be of type 'added'.
		 * Upon one element being updated that one will be of type 'modified'
		 * and the others will be of type 'added' or 'modified' if they were previously.
		 * Type 'remove' doesn't come through.
		 */
		return collection.snapshotChanges().pipe(
			takeUntil(auth.loggedOut),
			map((actions) => {
				const newMap = new Map<string, CollectionDocDao<Tm, Td>>();
				actions.forEach((action) => {
					newMap.set(action.payload.doc.ref.path, this.update(afs, auth, DAO, daoMap, action));
				});
				daoMap = newMap;
				// create a new array to change the reference
				return Array.from(daoMap.values()).map((x) => x.dao);
			}),
			shareReplay(1)
		);
	}

	/**
	 * Add or update DAOs.
	 * @param DAO DAO constructor.
	 * @param daos Current daos.
	 * @param action Entity metadata.
	 */
	private static update<Tm extends object, Td extends BaseDAO<Tm>>(
		afs: AngularFirestore,
		auth: AuthService,
		DAO: new (...args: unknown[]) => Td,
		daos: Map<string, CollectionDocDao<Tm, Td>>,
		action: DocumentChangeAction<Tm>
	): CollectionDocDao<Tm, Td> {
		const { path } = action.payload.doc.ref;
		const snapshot = action.payload.doc.data();
		const { ref } = action.payload.doc;

		// set
		const wrap = daos.get(path);
		if (wrap != null) {
			wrap.data.next(snapshot);
			return wrap;
		}

		// add
		const { id } = action.payload.doc.ref;
		const stream = new BehaviorSubject<Tm>(snapshot);
		const value = new CollectionDaoValue(stream);
		const dao = new DAO(afs, auth, id, path, value, ref);
		return { data: stream, dao };
	}

	/**
	 * Create the root DAO for the given path.
	 * @param DAO DAO constructor
	 * @param path Path to the root doc.
	 */
	public build<Tm extends object, Td extends BaseDAO<Tm>>(
		DAO: new (...args: unknown[]) => Td,
		path: string,
		queryFn?: QueryFn
	): Observable<Td[]> {
		return DAOCollectionFactory.initCollection(this.afs, this.auth, DAO, path, queryFn);
	}
}
