import { AbstractControl, ValidatorFn } from '@angular/forms';

import { isEmptyInputValue } from './shared';

export function unmaskMax(max: number): ValidatorFn {
	return (control: AbstractControl<unknown>) => {
		const { value } = control;
		if (isEmptyInputValue(value) || isEmptyInputValue(max)) {
			return null; // don't validate empty values to allow optional controls
		}
		let stringValue = typeof value === 'string' ? value : `${JSON.stringify(value)}`;
		stringValue = stringValue.replace(/[^0-9.-]/g, '');
		const floatValue = parseFloat(stringValue);
		// Controls with NaN values after parsing should be treated as not having a
		// maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
		return !Number.isNaN(floatValue) && floatValue > max ? { max: { max: floatValue, actual: value } } : null;
	};
}
