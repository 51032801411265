import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UpdateData } from '@angular/fire/firestore';

import { addYears } from 'date-fns';
import firebase from 'firebase/compat/app';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LogService, LogZone } from '@app/shared/core';
import { ArrayUtil } from '@app/shared/shared/utils/array.util';

import { isInStrategyYear, IssueUpdate, Meeting, Strategy, strategyStartDate } from '../models';
import { Path } from '../utils/path';
import { BaseRepository } from './base-repository.service';

@Injectable()
export class MeetingRepository extends BaseRepository<Meeting> {
	constructor(
		private afs: AngularFirestore,
		private log: LogService
	) {
		super();
		this.log = log.for(LogZone.PERSIST);
	}

	public getCurrentMeetings(tenantId: string): Observable<Meeting[]> {
		const path = Path.meetings(tenantId);
		return this.afs
			.collection<Meeting>(path, (x) => x.where('isCurrent', '==', true))
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));
	}

	public getPreviousMeeting(tenantId: string, before?: firebase.firestore.Timestamp): Observable<Meeting> {
		const path = Path.meetings(tenantId);
		return this.afs
			.collection<Meeting>(path, (x) => {
				// anything without a completedOn is excluded because of the order by, i.e. we intentionally never get the current meeting
				let query = x.orderBy('completedOn', 'desc');
				if (before) {
					query = query.where('completedOn', '<', before);
				}
				return query;
			})
			.snapshotChanges()
			.pipe(map((x) => this.mapFirstSnapshot(x)));
	}

	public getMeeting(meetingPath: string): Observable<Meeting> {
		return this.afs
			.doc<Meeting>(meetingPath)
			.snapshotChanges()
			.pipe(map((x) => this.mapSingleGet(x.payload)));
	}

	public getMeetings(tenantId: string, strategy: Strategy): Observable<Meeting[]> {
		const path = Path.meetings(tenantId);
		const startDate = strategyStartDate(strategy);
		const endDate = addYears(startDate, 1);
		const query = this.afs
			.collection<Meeting>(path, (x) =>
				x
					.where('completedOn', '>=', firebase.firestore.Timestamp.fromDate(startDate))
					.where('completedOn', '<', firebase.firestore.Timestamp.fromDate(endDate))
			)
			.snapshotChanges()
			.pipe(map((x) => this.mapListSnapshot(x)));

		if (isInStrategyYear(strategy)) {
			return combineLatest([
				query,
				this.afs
					.collection<Meeting>(path, (x) => x.where('isCurrent', '==', true))
					.snapshotChanges()
					.pipe(map((x) => this.mapListSnapshot(x))),
			]).pipe(map((x) => this.mapUnique(x)));
		} else {
			return query;
		}
	}

	public getPrevIssueUpdate(
		tenantId: string,
		issueId: string,
		before: firebase.firestore.Timestamp
	): Observable<IssueUpdate> {
		const path = Path.meetings(tenantId);
		return this.afs
			.collection<Meeting>(path, (ref) => ref.orderBy(`issueUpdates.${issueId}`))
			.snapshotChanges()
			.pipe(
				map((results) => {
					if (!results.length) return null;
					const meetings = this.mapListSnapshot(results).filter((x) => !x.isCurrent && x.completedOn <= before);
					if (!meetings.length) return null;
					const sortedMeetings = ArrayUtil.sortByProp(meetings, (x) => x.completedOn);
					return sortedMeetings[sortedMeetings.length - 1].issueUpdates[issueId];
				})
			);
	}

	public async completeMeeting(tenantId: string, meetingId: string): Promise<void> {
		const path = Path.meeting(tenantId, meetingId);
		const item: UpdateData<Meeting> = {
			isCurrent: false,
			completedOn: firebase.firestore.FieldValue.serverTimestamp(),
		};
		this.log.debug('Update', { path, item });
		await this.afs.doc(path).update(item);
	}

	public async createCurrentMeeting(tenantId: string): Promise<void> {
		const path = Path.meetings(tenantId);
		const item: UpdateData<Meeting> = {
			createdOn: firebase.firestore.FieldValue.serverTimestamp(),
			isCurrent: true,
		};
		this.log.debug('Add', { path, item });
		await this.afs.collection(path).add(item);
	}
}
