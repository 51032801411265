import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
	ButtonNavigatorComponent, CardComponent, ConfirmationDialogComponent, CurrencyInputComponent, KpiBoxComponent,
	KpiProgressBarComponent, NumberInputComponent, OkDialogComponent, PercentInputComponent, SummaryCardComponent,
	TextAreaInputComponent, TextInputComponent,
} from './components';
import { DeleteDialogComponent } from './components/dialogs/delete-dialog/delete-dialog.component';
import { GtmButtonsComponent } from './components/gtm-buttons/gtm-buttons.component';
import { HelpTooltipComponent } from './components/help-tooltip/help-tooltip.component';
import { AutocompleteInputComponent } from './components/inputs/autocomplete-input.component';
import { CheckboxInputComponent } from './components/inputs/checkbox-input.component';
import { RadioOptionsComponent } from './components/inputs/radio-options.component';
import { SelectComponent } from './components/inputs/select.component';
import { IssueBacklogComponent } from './components/issue-backlog';
import {
	BacklogStatusTextPipe, IssueBacklogItemComponent,
} from './components/issue-backlog/issue-backlog-item/issue-backlog-item.component';
import { FocusDirective } from './directives';
import { OnOrInDirective } from './directives/on-or-in.directive';
import { SanitizePipe, ShortNumberPipe, SnapshotSortByPipe, TermPipe } from './pipes';
import { ScorecardColumnNumberPipe } from './pipes/scorecard-col-number.pipe';
import { AccordionExpansionService, PrintService } from './services';
import { DownloadService } from './services/download.service';
import { ProcessRepository } from './services/process.service';

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDialogModule,
		MatInputModule,
		MatRadioModule,
		MatSelectModule,
		ReactiveFormsModule,
		NgxMaskDirective,
	],
	declarations: [
		ButtonNavigatorComponent,
		CardComponent,
		ConfirmationDialogComponent,
		DeleteDialogComponent,
		CurrencyInputComponent,
		KpiBoxComponent,
		KpiProgressBarComponent,
		NumberInputComponent,
		PercentInputComponent,
		SummaryCardComponent,
		TextAreaInputComponent,
		TextInputComponent,
		SelectComponent,
		RadioOptionsComponent,
		FocusDirective,
		SanitizePipe,
		ShortNumberPipe,
		SnapshotSortByPipe,
		TermPipe,
		OkDialogComponent,
		IssueBacklogComponent,
		IssueBacklogItemComponent,
		BacklogStatusTextPipe,
		ScorecardColumnNumberPipe,
		OnOrInDirective,
		HelpTooltipComponent,
		GtmButtonsComponent,
		CheckboxInputComponent,
		AutocompleteInputComponent,
	],
	providers: [
		CurrencyPipe,
		DecimalPipe,
		DownloadService,
		SanitizePipe,
		TermPipe,
		ScorecardColumnNumberPipe,
		PrintService,
		AccordionExpansionService,
		provideNgxMask({ decimalMarker: '.' }),
		ProcessRepository,
	],
	exports: [
		ButtonNavigatorComponent,
		CardComponent,
		CommonModule,
		ConfirmationDialogComponent,
		DeleteDialogComponent,
		OkDialogComponent,
		CurrencyInputComponent,
		FontAwesomeModule,
		KpiBoxComponent,
		KpiProgressBarComponent,
		NumberInputComponent,
		PercentInputComponent,
		SummaryCardComponent,
		TextAreaInputComponent,
		TextInputComponent,
		SelectComponent,
		RadioOptionsComponent,
		IssueBacklogComponent,
		FocusDirective,
		SanitizePipe,
		ShortNumberPipe,
		SnapshotSortByPipe,
		TermPipe,
		ScorecardColumnNumberPipe,
		NgxMaskDirective,
		HelpTooltipComponent,
		GtmButtonsComponent,
		CheckboxInputComponent,
		AutocompleteInputComponent,
	],
})
export class SharedModule {}
