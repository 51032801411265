export enum FsCollection {
	ActionItems = 'actionItems',
	BookingsModels = 'bookingsModels',
	Campaigns = 'campaigns',
	CustomScorecards = 'customScorecards',
	Imperatives = 'imperatives',
	Initiatives = 'initiatives',
	Issues = 'issues',
	Markets = 'markets',
	Meetings = 'meetings',
	Metrics = 'metrics',
	Offerings = 'offerings',
	Opportunities = 'opportunities',
	Processes = 'processes',
	QualificationPaths = 'qualificationPaths',
	QualificationStages = 'qualificationStages',
	QuarterlyPlans = 'quarterlyPlans',
	RevenueStreams = 'revenueStreams',
	Segments = 'segments',
	SegmentValues = 'segmentValues',
	StaffingModels = 'staffingModels',
	Strategies = 'strategies',
	StreamData = 'streamData',
	Tenants = 'tenants',
	Users = 'users',
	WeeklyUpdates = 'weeklyUpdates',
}

export interface PathIds {
	userId?: string;
	tenantId?: string;
	strategyId?: string;

	actionItemId?: string;
	assetId?: string;
	assetTypeId?: string;
	bookingsModelId?: string;
	campaignId?: string;
	channelId?: string;
	imperativeId?: string;
	initiativeId?: string;
	issueId?: string;
	revenueStreamId?: string;
	marketAttributeId?: string;
	meetingId?: string;
	packageId?: string;
	processId?: string;
	programId?: string;
	quarterlyPlanId?: string;
	stakeholderAttributeId?: string;
	stakeholderId?: string;
	tacticId?: string;
	weeklyUpdateId?: string;
}

export class Path {
	public static users(): string {
		return FsCollection.Users;
	}

	public static user(userId: string): string {
		return `${this.users()}/${userId}`;
	}

	public static tenants(): string {
		return FsCollection.Tenants;
	}

	public static tenant(tenantId: string): string {
		return `${this.tenants()}/${tenantId}`;
	}

	public static strategies(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.Strategies}`;
	}

	public static strategy(tenantId: string, strategyId: string): string {
		return `${this.strategies(tenantId)}/${strategyId}`;
	}

	public static markets(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.Markets}`;
	}

	public static offerings(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.Offerings}`;
	}

	public static issues(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.Issues}`;
	}

	public static issue(tenantId: string, issueId: string): string {
		return `${this.issues(tenantId)}/${issueId}`;
	}

	public static actionItems(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.ActionItems}`;
	}

	public static actionItem(tenantId: string, actionItemId: string): string {
		return `${this.actionItems(tenantId)}/${actionItemId}`;
	}

	public static meetings(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.Meetings}`;
	}

	public static meeting(tenantId: string, meetingId: string): string {
		return `${this.meetings(tenantId)}/${meetingId}`;
	}

	public static initiatives(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.Initiatives}`;
	}

	public static initiative(tenantId: string, strategyId: string, initiativeId: string): string {
		return `${this.initiatives(tenantId, strategyId)}/${initiativeId}`;
	}

	public static customScorecards(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.CustomScorecards}`;
	}

	public static customScorecard(tenantId: string, strategyId: string, customScorecardId: string): string {
		return `${this.customScorecards(tenantId, strategyId)}/${customScorecardId}`;
	}

	public static quarterlyPlans(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.QuarterlyPlans}`;
	}

	public static quarterlyPlan(tenantId: string, strategyId: string, quarterlyPlanId: string): string {
		return `${this.quarterlyPlans(tenantId, strategyId)}/${quarterlyPlanId}`;
	}

	public static weeklyUpdates(tenantId: string, strategyId: string, quarterlyPlanId: string): string {
		return `${this.quarterlyPlan(tenantId, strategyId, quarterlyPlanId)}/${FsCollection.WeeklyUpdates}`;
	}

	public static weeklyUpdate(
		tenantId: string,
		strategyId: string,
		quarterlyPlanId: string,
		weeklyUpdateId: string
	): string {
		return `${this.weeklyUpdates(tenantId, strategyId, quarterlyPlanId)}/${weeklyUpdateId}`;
	}

	public static imperatives(tenantId: string, strategyId: string, quarterlyPlanId: string): string {
		return `${this.quarterlyPlan(tenantId, strategyId, quarterlyPlanId)}/${FsCollection.Imperatives}`;
	}

	public static imperative(
		tenantId: string,
		strategyId: string,
		quarterlyPlanId: string,
		imperativeId: string
	): string {
		return `${this.imperatives(tenantId, strategyId, quarterlyPlanId)}/${imperativeId}`;
	}

	public static processes(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.Processes}`;
	}

	public static process(tenantId: string, strategyId: string, processId: string): string {
		return `${this.processes(tenantId, strategyId)}/${processId}`;
	}

	public static bookingsModels(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.BookingsModels}`;
	}

	public static bookingsModel(tenantId: string, strategyId: string, bookingsModelId: string): string {
		return `${this.bookingsModels(tenantId, strategyId)}/${bookingsModelId}`;
	}

	public static streamDataCollection(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.StreamData}`;
	}

	public static streamData(tenantId: string, strategyId: string, revenueStreamId: string): string {
		return `${this.streamDataCollection(tenantId, strategyId)}/${revenueStreamId}`;
	}

	public static staffingModels(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.StaffingModels}`;
	}

	public static staffingModel(tenantId: string, strategyId: string, staffingModelId: string): string {
		return `${this.staffingModels(tenantId, strategyId)}/${staffingModelId}`;
	}

	public static revenueStreams(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.RevenueStreams}`;
	}

	public static revenueStream(tenantId: string, revenueStreamId: string): string {
		return `${this.revenueStreams(tenantId)}/${revenueStreamId}`;
	}

	public static segments(tenantId: string): string {
		return `${this.tenant(tenantId)}/${FsCollection.Segments}`;
	}

	public static segment(tenantId: string, segmentId: string): string {
		return `${this.segments(tenantId)}/${segmentId}`;
	}

	public static segmentValues(tenantId: string, segmentId: string): string {
		return `${this.segment(tenantId, segmentId)}/${FsCollection.SegmentValues}`;
	}

	public static campaigns(tenantId: string, strategyId: string): string {
		return `${this.strategy(tenantId, strategyId)}/${FsCollection.Campaigns}`;
	}

	public static campaign(tenantId: string, strategyId: string, campaignId: string): string {
		return `${this.campaigns(tenantId, strategyId)}/${campaignId}`;
	}

	public static getIds(path: string): PathIds {
		const ids = {} as PathIds;
		const idMatches = path.match(/(\w+\/\w+)/g);
		for (const idPart of idMatches) {
			const [idPrefix, id] = idPart.split('/');
			switch (idPrefix as FsCollection) {
				case FsCollection.Tenants:
					ids.tenantId = id;
					break;
				case FsCollection.Strategies:
					ids.strategyId = id;
					break;
				case FsCollection.Issues:
					ids.issueId = id;
					break;
				case FsCollection.ActionItems:
					ids.actionItemId = id;
					break;
				case FsCollection.QuarterlyPlans:
					ids.quarterlyPlanId = id;
					break;
				case FsCollection.WeeklyUpdates:
					ids.weeklyUpdateId = id;
					break;
				case FsCollection.Imperatives:
					ids.imperativeId = id;
					break;
				case FsCollection.BookingsModels:
					ids.bookingsModelId = id;
					break;
				case FsCollection.Initiatives:
					ids.initiativeId = id;
					break;
				case FsCollection.RevenueStreams:
					ids.revenueStreamId = id;
					break;
				case FsCollection.StreamData:
					ids.revenueStreamId = id;
					break;
				case FsCollection.Campaigns:
					ids.campaignId = id;
					break;
				case FsCollection.Processes:
					ids.processId = id;
					break;
				case FsCollection.Meetings:
					ids.meetingId = id;
					break;
				case FsCollection.CustomScorecards:
				case FsCollection.Metrics:
				case FsCollection.Opportunities:
				case FsCollection.QualificationPaths:
				case FsCollection.QualificationStages:
				case FsCollection.StaffingModels:
				case FsCollection.Users:
				case FsCollection.Markets:
				case FsCollection.Offerings:
				case FsCollection.Segments:
				case FsCollection.SegmentValues:
					throw new Error('PathId case not implemented yet');
			}
		}
		return ids;
	}
}
