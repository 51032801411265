type PropSelector<T, Tv> = (x: T) => Tv;

export class ArrayUtil {
	/**
	 * Sorts an array by a property on it.
	 * This is NOT an in-place sort (aka it does not modify the original array).
	 * @param items items to sort.
	 * @param sortKeys selector for the property to sort by.
	 * @param options options for special sorting
	 */

	public static sortByProp<T>(
		items: T[],
		sortKeys: PropSelector<T, unknown> | PropSelector<T, unknown>[],
		options?: { caseInsensitive: boolean }
	): T[] {
		const sortKeysArray = Array.isArray(sortKeys) ? sortKeys : [sortKeys];
		return [...items].sort((a, b) => {
			for (const sortKey of sortKeysArray) {
				let aProp = sortKey(a);
				let bProp = sortKey(b);
				if (options && options.caseInsensitive && typeof aProp === 'string' && typeof bProp === 'string') {
					aProp = aProp.toLowerCase();
					bProp = bProp.toLowerCase();
				}
				if (aProp < bProp) {
					return -1;
				}
				if (aProp > bProp) {
					return 1;
				}
			}
			return 0;
		});
	}

	public static flat<T>(items: T[][]): T[] {
		return items.reduce((acc, val) => acc.concat(val), []);
	}

	public static toMap<T, TKey>(items: T[], keySelector: PropSelector<T, TKey>): Map<TKey, T> {
		return new Map<TKey, T>(items.map<[TKey, T]>((i) => [keySelector(i), i]));
	}
}
